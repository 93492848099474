<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">专项规划二所</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">专项规划二所主要职责：</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担国土空间生态修复相关专项规划、林业相关专项规划、自然保护地相关专项规划、历史文化保护相关专项规划、公共服务设施布局相关专项规划等，同时承接乡镇国土空间总体规划、详细规划、概念规划、城市设计、选址论证、社区规划与更新及乡村振兴等专题研究工作。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副所长：周瑾（负责人）、谭琪</b></span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/专项规划二所.jpeg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>